<template>
  <div class="footer">
    <ul class="logo_livrio">리브리오</ul>
    <ul class="menu">
      <a href="https://www.livrio.co.kr" target="_blank">리브리오 소개</a><span class="bar">|</span>
      <a @click.prevent="showPolicy">이용약관</a><span class="bar">|</span>
      <a @click.prevent="showPrivacy">개인정보처리방침</a>
    </ul>
    <ul>
      <p>주식회사 리브리오 <span class="bar">|</span> 대표 : 이상훈
        <br>대표번호 : 1811-0793 <span class="bar">|</span> 이메일 : <a
          href="mailto:3174mt@livrio.co.kr">3174mt@livrio.co.kr</a>
      </p>
      <p>사업자등록번호 : 648-88-01401
        <br> 주소 : 서울시 강남구 논현로28길 48, 5층
      </p>
      <span class="corp_name">© livrio Corp.</span>
    </ul>

  </div>
</template>
<script>
export default {
  methods:{
    showPolicy: function () {
      // this.modalCompo = PolicyPopup;
      // this.isModalViewed = true;
      this.$router.push({
        name: "AnonymousPolicy",
      })
    },
    showPrivacy: function () {
      // this.modalCompo = PrivacyPopup;
      // this.isModalViewed = true;
      this.$router.push({
        name: "AnonymousPrivacy",
      })
    },
  }
}
</script>
