import * as user from './mock/mockUser';
import * as payment from './mock/mockPayment';
import * as sendList from './mock/mockSendList';
import * as tutorial from './mock/mockTutorial';

const hash = {
  ...user.default,
  ...payment.default,
  ...sendList.default,
  ...tutorial.default,
};

const getMock = (path) => {
  console.log(hash);

  const data = hash[path];

  console.log("mock result:" + path);
  console.log(data);

  return data ? JSON.parse(JSON.stringify(data)) : null;
};

export default {
  getMock
};
