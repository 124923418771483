<template>
  <div class="mob">
    <div-header></div-header>
    <router-view />
    <div-footer></div-footer>
  </div>
</template>

<script>
import DivHeader from './HeaderMobile.vue'
import DivFooter from './FooterMobile.vue'
export default {
  name: 'LayoutMobilePage',
  components: {
    DivHeader,
    DivFooter
  }
}
</script>
