<template>
  <div class="header">
    <h1 class="logo">싼톡</h1>
    <!-- <button class="btn" @click="navMain('LoginPage')">로그인</button> -->
  </div>

</template>
<script>
export default {
  name: "MainPageMobileHeader",
  methods: {
    navMain(mainPath) {
      // console.log(this.$route.params.recommendCode);
      this.$router.push({
        name: mainPath,
        query: {
          recommendCode:
            this.$route.params.recommendCode || this.$route.query.recommendCode,
        },
      })
    },
  }
}
</script>
