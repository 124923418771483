const hangulExp = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g;
const exceptNumberExp = /[^0-9]/g;
const regexp_email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const regexp_blank = /^\s*$/;

const pwdRegexp = {
  lower: /[a-z]/g,
  upper: /[A-Z]/g,
  alpha: /[A-Z]/gi,
  numeric: /[0-9]/g,
  special: /[\W_]/g
};

const passwordCheckConfig = {
  lower: 0,
  upper: 0,
  alpha: 1, /* lower + upper */
  numeric: 1,
  special: 1,
  length: [
    8,
    Infinity],
  custom: [ /* regexes and/or functions */],
  badWords: [],
  badSequenceLength: 0,
  noQwertySequences: false,
  noSequential: false
};

const password_sequence_lower = "abcdefghijklmnopqrstuvwxyz";
const password_sequence_upper = password_sequence_lower.toUpperCase();
const password_sequence_numbers = "0123456789";
const password_sequence_qwerty = "qwertyuiopasdfghjklzxcvbnm";


export default {
  copyObject($source){
    const serialized = JSON.stringify($source);

    return JSON.parse(serialized);
  },
  cleanNoneNumber (text) {
    if (exceptNumberExp.test(text)) {
      return text.replace(exceptNumberExp, '');
    }
    return text;
  },
  cleanHangul (text) {
    if (hangulExp.test(text)) {
      return text.replace(hangulExp, '');
    }
    return text;
  },
  isValidEmail (email) {
    return regexp_email.test(email);
  },
  isValidPassword (pw, config) {
    if (config === void 0) {
      config = {};
    }

    config = Object.assign({}, passwordCheckConfig, config);

    let rule = "";

    let i;

    // enforce min/max length
    if (pw.length < config.length[0]) {
      return "invalid length";
    }

    if (pw.length > config.length[1]) {
      return "invalid length";
    }

    // enforce lower/upper/alpha/numeric/special rules
    for (rule in pwdRegexp) {
      if ((pw.match(pwdRegexp[rule]) || []).length < config[rule]) {
        switch (rule) {
          case "lower": {
            return "소문자를 한자 이상 포함해야합니다.";
          }
          case "upper": {
            return "대문자를 한자 이상 포함해야합니다.";
          }
          case "alpha": {
            return "영문을 한자 이상 포함해야합니다.";
          }
          case "numeric": {
            return "숫자를 포함해야합니다.";
          }
          case "special": {
            return "특수문자를 포함해야합니다.";
          }
        }
        return "규칙:" + rule + " 에 위배됩니다.";
      }
    }

    // enforce word ban (case insensitive)
    for (i = 0; i < config.badWords.length; i++) {
      if (pw.toLowerCase().indexOf(config.badWords[i].toLowerCase()) > -1) {
        return config.badWords[i] + " 를 포함하지 못합니다.";
      }
    }

    // enforce alphanumeric/qwerty sequence ban rules
    if (config.badSequenceLength) {
      let start = config.badSequenceLength - 1;
      let seq = "_" + pw.slice(0, start);

      for (i = start; i < pw.length; i++) {
        seq = seq.slice(1) + pw.charAt(i);
        if (password_sequence_lower.indexOf(seq) > -1
          || password_sequence_upper.indexOf(seq) > -1
          || password_sequence_numbers.indexOf(seq) > -1
          || (config.noQwertySequences && password_sequence_qwerty.indexOf(seq) > -1)) {
          return "연속된 문자열은 부적절 합니다.";
        }
      }
    }
    return false
  },
  // check string empty
  isEmpty(value) {
    return value === void 0 || !value || value.length === 0;
  },
  hasText(value) {
    return !this.isEmpty(value);
  },
  isBlank(value) {
    return this.hasText(value) && regexp_blank.test(value);
  },
  escapeHtml(value) {
    let text_node = document.createTextNode(value);
    let p_tag = document.createElement('p');
    p_tag.appendChild(text_node);

    return p_tag.innerHTML;
  },
  unescapeHtml(value) {
    let text_area = document.createElement('textarea');
    text_area.innerHTML = value;
    // handle case of empty input
    return text_area.childNodes.length === 0 ? "" : text_area.childNodes[0].nodeValue;
  },
  extractObjectToParam(prefix, ary, object) {
    for (let key in object) {
      if (!Object.prototype.hasOwnProperty.call(object, key)) {
        continue;
      }

      let value = object[key];

      let vType = typeof value;

      // filter blank
      if (value == null || value === void 0 || vType === 'undefined') {
        // do nothing
      }
      // single type
      else if (vType === 'string' || vType === 'number' || vType === 'boolean' || vType === 'bigint') {
        ary.push(encodeURIComponent(prefix ? prefix + "." + key : key) + "=" + encodeURIComponent(value));
      }
      // array type
      else if (value.constructor.name === 'Array') {
        let that = this;
        value.forEach( function(elm, idx) {
          let nextPrefix = (prefix ? prefix + "." + key : key) + "[" + idx + "]";
          that.extractObjectToParam(nextPrefix, ary, elm);
        });
      }
      // object type
      else if (value.constructor.name === 'Object') {
        let nextPrefix = (prefix ? prefix + "." + key : key);
        this.extractObjectToParam(nextPrefix, ary, value);
      }
      else {
        console.log("unknown-model:" + value);
      }
    }
  }
}
