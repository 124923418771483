<template>
  <div id="wrapper">
    <div id="contents">

        <router-view />

    </div>
    <!-- <div-footer></div-footer> -->
  </div>
</template>

<script>

export default {
  name: 'LayoutPage',
  components: {
  }
}
</script>

<style scoped>
#wrapper{
  padding-bottom:0;
  position: unset;
}
</style>
