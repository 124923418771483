import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
// import Vuetify, {
//   VApp, // required
//   VNavigationDrawer,
//   VFooter,
//   VToolbar,
//   VFadeTransition
// } from 'vuetify/lib'
import filter from './plugins/filters';
// import Multiselect from 'vue-multiselect'
import ActionPopup from '@/views/components/ActionPopup';
import AlertPopup from '@/views/components/AlertPopup';
import Paging from "@/views/components/PagerPaging";

// import jQuery from 'jquery';

import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
// import cretePersistedState from "vuex-persistedstate";

// register globally

Vue.component('actionPopup', ActionPopup);
Vue.component('PagerPaging', Paging);
Vue.component('alertPopup', AlertPopup);


// global.$ = jQuery
Vue.config.productionTip = false

for (const key in filter) {
  Vue.filter( key , filter[key]);
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
