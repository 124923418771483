<template>
  <div>
    <div v-if="loading">
      <img src="resources/images/user_temp.png">
    </div>
    <div v-if="!loading">
      <img style="width:100%;height:100%" :src="'http://openweathermap.org/img/wn/'+icon+'.png'">

      <!-- <p>{{ weather }}</p> -->
    </div>

  </div>
</template>

<script>
const API_KEY = "0080313c5b902284b0ad3c6203c10f2d"
//037bd4ca77c47ad7d2fd81d3ec614b79 - Default
export default {
  data() {
    return {
      loading: true,
      weather: null,
      icon : null,
      geolocationError: false, // 위치 정보 가져오기 실패 여부를 저장할 변수
    };
  },
  mounted() {
    navigator.geolocation.getCurrentPosition(
      position => {
        const { latitude, longitude } = position.coords;
        const url = `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=${API_KEY}&lang=kr`;
        //`api.openweathermap.org/data/2.5/forecast/daily?lat=${latitude}&lon=${longitude}&cnt=1&appid=${API_KEY}`
        fetch(url)
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(data => {
            console.log(data) //weather[0].main은 영어로옴 Clouds.. 
            this.weather = data.weather[0].description;
            this.icon = data.weather[0].icon;
            this.loading = false;
          })
          .catch(error => {
            console.error('Error:', error);
            this.loading = false;
          });
      },
      error => { //위치 에러거나, 사용자가 위치 정보 사용 거부했거나 
        console.log(error)
        this.geolocationError = true; // 위치 정보 가져오기 실패로 
        //리브리오 위도경도 (37.4845377, 127.0507572)
        const url = `https://api.openweathermap.org/data/2.5/weather?lat=37.4845377&lon=127.0507572&appid=${API_KEY}&lang=kr`;
        //`api.openweathermap.org/data/2.5/forecast/daily?lat=${latitude}&lon=${longitude}&cnt=1&appid=${API_KEY}`
        fetch(url)
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(data => {
            this.weather = data.weather[0].description;
            this.icon = data.weather[0].icon;
            this.loading = false;
          })
          .catch(error => {
            console.error('Error:', error);
            this.loading = false;
          });
      }
    )


  },
};
</script>
