<template>
  <transition name="modal">
    <div class="pop_bg" style="z-index:999" @click.self.prevent="closeModal()">
    <div class="pop" 
      v-bind:class="[{'pop_md': modalSizeUp}, 'pop_sm']"  style="position: relative;z-index:1000 ">
      <div class="pop_body pop_pw_error" >
        <a class="btn_pop_close" @click="closeModal">닫기</a>
        <h2 ><slot name="body"/></h2>
      </div>
      <div class="pop_footer" v-if="closeName"> 
         <a class="btL-md" @click="closeModal()">{{ closeName }}</a>
      </div>
    </div>
    </div>
  </transition>
</template>

<script>
import ModalHandler from "@/classes/ModalHandler";

export default {
  name: "AlertPopup",
  props: {
    title: { type: String },
    dialog: { type: ModalHandler },
    closeName: { type: String },
    modalSizeUp : {type: String},
  },
  data: () => ({
  }),
  computed: {},
  methods: {
    closeModal () {
     this.$emit('close-modal');
    },
    onEscKeyDown(e) {
      if (e.key === "Escape" || e.keyCode === 27 ) {
        this.closeModal();
      }
    },

  },
  mounted() {
    window.addEventListener("keydown", this.onEscKeyDown);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.onEscKeyDown);
  },
};
</script>

